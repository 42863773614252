import {createWorkbook} from 'protobi-msexcel-builder'
import {downloadBlob} from "external/bowser"

export {default as exportCSV} from 'save-csv'
export {default as XLSX} from 'xlsx'

export async function exportBook(filename, sheets = [], cb) {
    const workbook = createWorkbook()
    cb && cb({type: 'pre', target: 'workbook', context: {workbook}})
    sheets.forEach(([name, rows]) => {
        const sheet = workbook.createSheet(name, rows[0].length, rows.length)
        cb && cb({type: 'pre', target: 'sheet', context: {workbook, sheet, name, rows}})
        rows.forEach((columns, rowIndex) => {
            cb && cb({type: 'pre', target: 'row', context: {workbook, sheet, name, rows, columns, rowIndex}})
            columns.forEach((column, columnIndex) => {
                cb && cb({type: 'pre', target: 'column', context: {workbook, sheet, name, rows, columns, rowIndex, column, columnIndex}})
                sheet.set(columnIndex + 1, rowIndex + 1, column)
                cb && cb({type: 'post', target: 'column', context: {workbook, sheet, name, rows, columns, rowIndex, column, columnIndex}})

            })
            cb && cb({type: 'post', target: 'row', context: {workbook, sheet, name, rows, columns, rowIndex}})
        })
        cb && cb({type: 'post', target: 'sheet', context: {workbook, sheet, name, rows}})
    })
    cb && cb({type: 'post', target: 'workbook', context: {workbook}})
    const jszip = await workbook.generate()
    const blob = await jszip.generateAsync({type: "blob", mimeType: 'application/vnd.ms-excel;'})
    downloadBlob(filename, blob)

}

export function addRange(sheet, range, origin = {}) {

    const {column: startColumn = 0, row: startRow = 0} = origin  
    const [firstRow] = sheet
    const [firstRangeRow] = range

    const maxRow = sheet.length 
    const maxColumn = firstRow.length

    const maxRangeRow = startRow + range.length 
    const maxRangeColumn = startColumn + firstRangeRow.length
    
    const missingRows = maxRangeRow - maxRow
    const missingColumns = maxRangeColumn - maxColumn

    
    const enoughRows = missingRows > 0
        ? sheet.concat(Array(missingRows).fill().map(() => Array(firstRow.length).fill({set: ''})))
        : sheet

    const enoughColumns = missingColumns > 0
        ? enoughRows.map(columns => columns.concat(Array(missingColumns).fill({set: ''})))
        : enoughRows

    range.forEach((columns, rowIndex) => {
        const currentRow = enoughColumns[startRow + rowIndex]
        columns.forEach((value, columnIndex) => {
            currentRow[startColumn + columnIndex] = value
        })
    })

    return enoughColumns

}

